import { Avatar } from '@mui/material';

import { TEMP_PHOTO_URL } from '../../utils';

const AccountInfo = () => {
  // TODO: Test scopes to check if we can still include user photo
  // const { photo, isLoading } = useUserPhoto();

  // if (isLoading) {
  //   return <CircularProgress />;
  // }

  return <Avatar src={TEMP_PHOTO_URL} alt="user account photo" />;
};

export { AccountInfo };
