import { Configuration } from '@azure/msal-browser';

const SCOPE_URL = process.env.REACT_APP_CONFIG_MSAL_SCOPE;
const TENANT_ID = process.env.REACT_APP_CONFIG_MSAL_TENANT_ID;
const API_URL = process.env.REACT_APP_CONFIG_API_URL;

const B2B_CONFIG: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CONFIG_MSAL_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

const AUTH_REDIRECT_PATH = 'WIT_AUTH_REDIRECT_PATH';

export { API_URL, SCOPE_URL, B2B_CONFIG, AUTH_REDIRECT_PATH };
