import { useState } from 'react';

import Cookies from 'js-cookie';

const useCookie = (
  key: string,
  initialValue: string | object = '',
): [string | object, (value: string | object, options?: Cookies.CookieAttributes) => void] => {
  const [item, setInnerValue] = useState<string | object>(Cookies.get(key) || initialValue);

  const setValue = (value: string, options?: Cookies.CookieAttributes) => {
    setInnerValue(value);
    Cookies.set(key, value, options);
  };

  return [item, setValue];
};

export { useCookie };
