import { AxiosError } from 'axios';
import { v4 } from 'uuid';

import { Notification, NotificationType } from '../../models/notification';

export const prepareNotification = (err: AxiosError, type: NotificationType, defaultMsg?: string) => {
  const message = err?.message || defaultMsg || 'Something went wrong';

  const notification: Notification = {
    id: v4(),
    type,
    message,
    date: new Date().toISOString(),
  };

  return notification;
};
