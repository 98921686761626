import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import { MsalProvider } from '@azure/msal-react';

import { router } from './routes/browserRouter';
import { createMsalInstance } from './services/azure';
import './styles/main.scss';
import { Theme } from './theme';
import { ReduxWrapper } from './wrappers';

const App: FC = () => {
  const msalInstance = createMsalInstance();

  return (
    <MsalProvider instance={msalInstance}>
      <Theme>
        <ReduxWrapper>
          <RouterProvider router={router} />
        </ReduxWrapper>
      </Theme>
    </MsalProvider>
  );
};

export default App;
