import React, { FC, ReactNode } from 'react';
import { Provider } from 'react-redux';

import { useMsal } from '@azure/msal-react';

import store from '../store/root';

import Api from '../services/api';
import { acquireAccessToken } from '../services/azure';

interface IReduxWrapperProps {
  children: ReactNode;
}

const ReduxWrapper: FC<IReduxWrapperProps> = ({ children }) => {
  const { instance } = useMsal();

  const getToken = () => acquireAccessToken(instance);

  Api.setStore(store);
  Api.setGetTokenFunc(getToken);

  return <Provider store={store}>{children}</Provider>;
};

export { ReduxWrapper };
