import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';

export const TEMP_PHOTO_URL = 'https://i.imgur.com/mCHMpLT.png';
const GRAPH_URL = 'https://graph.microsoft.com/v1.0/me/photo/$value';

export function useUserPhoto() {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState(TEMP_PHOTO_URL);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const accessToken = await instance.acquireTokenSilent({
          scopes: ['User.Read'],
        });

        const response = await fetch(GRAPH_URL, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + accessToken.accessToken,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw Error('Response not ok');
        }
        const blob = await response.blob();

        setPhoto(URL.createObjectURL(blob));

        setIsLoading(false);
      } catch (err) {
        console.log('Could not fetch user photo -', err);
        setIsLoading(false);
      }
    })();
  }, [instance]);

  return { photo, isLoading };
}
