import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import styled from 'styled-components';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, CircularProgress } from '@mui/material';

import { AppDispatch } from '../../store/root';
import { fetchUserMe } from '../../store/users';

import { useCookie } from '../../utils/hooks';

import { AUTH_REDIRECT_PATH } from '../../config';
import { loginAzure } from '../../services/azure';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  width: 60vw;
  height: 60vh;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledImage = styled.img`
  height: 40px;
  margin-bottom: 40px;
`;

const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts[1]};
  font-size: ${(props) => props.theme.fontSizes.md};
`;

export const LoginPage: FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [redirectURL] = useCookie(AUTH_REDIRECT_PATH, '/');
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  useEffect(() => {
    dispatch(fetchUserMe());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectURL);
    }
  }, [isAuthenticated, navigate, redirectURL]);

  const handleLogin = async () => {
    await loginAzure(instance);
  };

  if (inProgress) {
    return (
      <StyledWrapper>
        <StyledBox>
          <Column>
            <StyledImage src="/icons/VfLogo.svg" alt="Vattenfall Logo" />

            <CircularProgress />
          </Column>
        </StyledBox>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <StyledBox>
        <Column>
          <StyledImage src="/icons/VfLogo.svg" alt="Vattenfall Logo" />
          <Header>WIT</Header>
        </Column>
        <Button variant="contained" onClick={handleLogin}>
          Login
        </Button>
      </StyledBox>
    </StyledWrapper>
  );
};
