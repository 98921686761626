import { PermissionRoles } from '../../models/roles';
import { User } from '../../models/user';

export const filterRoles = (user: Partial<User>) => (rolesPerRoute: string[]) => {
  if (user.roles.includes('ADMIN')) {
    return true;
  }

  if (rolesPerRoute.includes('ALL')) {
    if (user.roles.length > 0 || user.windBUMember || user.contractor) {
      return true;
    }
  }

  for (let r of rolesPerRoute) {
    if (user.roles?.includes(r as PermissionRoles)) {
      return true;
    }
  }

  return false;
};
