import { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

const Page404Image: FC = () => {
  return (
    <Box
      style={{
        borderRadius: '20px',
        position: 'relative',
        maxWidth: '100vw',
        backgroundImage: `url('/images/WindOfShore.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '80vh',
      }}
    >
      <Box
        style={{
          borderRadius: '20px',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0px',
          left: '0px',
          background: 'rgba(0, 0, 0, 0.2)',
        }}
      >
        <Box
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '500px',
            margin: '-0.75em auto',
            textAlign: 'center',
            color: 'white',
          }}
        >
          <Typography variant="h1" style={{ fontWeight: 800 }}>
            404
          </Typography>
          <Typography variant="h5">Page you are looking for does not exist.</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Page404: FC = () => {
  return (
    <>
      <Grid>
        <Grid item xs={12}>
          <Page404Image />
        </Grid>
      </Grid>
    </>
  );
};

export { Page404 };
