import { FC } from 'react';
import { Outlet } from 'react-router';

import styled from 'styled-components';

import { Header } from '../header';
import { Navigation } from '../navigation';

const Main = styled.main`
  margin: 73px 0 0 200px;
  min-height: 80vh;
  background: #fff;
  padding: 20px;
`;

const Layout: FC = () => {
  return (
    <>
      <Navigation />
      <Header />
      <Main>
        <Outlet />
      </Main>
    </>
  );
};

export { Layout };
