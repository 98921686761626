import {
  AuthenticationResult,
  EventMessage,
  EventType,
  IPublicClientApplication,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import type {} from '@azure/msal-react';

import { B2B_CONFIG, SCOPE_URL } from '../config';

const loginRequest = {
  scopes: [SCOPE_URL, 'User.Read', 'openid'],
};

const silentRequest = {
  ...loginRequest,
};

const createMsalInstance = () => {
  const msalInstance = new PublicClientApplication(B2B_CONFIG);

  msalInstance.addEventCallback((event: EventMessage) => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as AuthenticationResult).account) {
      const account = (event.payload as AuthenticationResult).account;
      msalInstance.setActiveAccount(account);
    }
  });

  msalInstance
    .handleRedirectPromise()
    .then(async (tokenResponse) => {
      // Check if user signed inlocalhost:8081
      if (!tokenResponse) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
          // No user signed in
          msalInstance.loginRedirect();
        }
      } else {
        // Do something with the tokenResponse
      }
    })
    .catch((err) => {
      // TODO: Handle errors
      console.log(err);
    });

  return msalInstance;
};

const loginAzure = async (msalInstance: IPublicClientApplication) => {
  try {
    const account = msalInstance.getActiveAccount();
    if (account) {
      // No user signed in
      msalInstance.loginPopup();
    }
  } catch (err) {
    console.error(err);
  }
};

const logoutAzure = async (msalInstance: IPublicClientApplication) => {
  try {
    await msalInstance.logoutRedirect();
  } catch (err) {
    console.error(err);
  }
};

const acquireAccessToken = async (msalInstance: IPublicClientApplication) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    await loginAzure(msalInstance);
  }
  const request = {
    ...loginRequest,
    account: activeAccount || accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request as SilentRequest);

  return authResult.accessToken;
};

export { createMsalInstance, loginAzure, loginRequest, silentRequest, logoutAzure, acquireAccessToken };
