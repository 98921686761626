import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User } from '../models/user';
import Api from '../services/api';
import { prepareNotification } from '../utils';
import { triggerNotification } from './notifications';
import { ReduxStoreType } from './store';

const sliceName = 'users';

const fetchUserMe = createAsyncThunk(`${sliceName}/fetchUsersMe`, async (_, { dispatch }): Promise<User> => {
  try {
    const response = await Api.getUser();

    return response.data;
  } catch (error: any) {
    const notification = prepareNotification(error, 'error', 'We could not fetch user details');
    dispatch(triggerNotification(notification));

    throw error;
  }
});

export interface IUsersStore {
  me: Partial<User>;
  isLoading: boolean;
}

const initialState: IUsersStore = {
  me: {
    roles: [],
  },
  isLoading: false,
};

const usersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserMe.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserMe.fulfilled, (state, { payload }) => {
      state.me = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchUserMe.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const getUserMe = (state: ReduxStoreType): Partial<User> => (state[sliceName] as IUsersStore).me;
const getUserMeIsLoading = (state: ReduxStoreType): boolean => (state[sliceName] as IUsersStore).isLoading;
const getIsAdmin = (state: ReduxStoreType): boolean =>
  Boolean((state[sliceName] as IUsersStore).me.roles?.includes('ADMIN'));
const getIsContractor = (state: ReduxStoreType): boolean => Boolean((state[sliceName] as IUsersStore).me.contractor);
const getIsWindBUMember = (state: ReduxStoreType): boolean =>
  Boolean((state[sliceName] as IUsersStore).me.windBUMember);

export {
  fetchUserMe,
  getIsAdmin,
  getIsContractor,
  getIsWindBUMember,
  getUserMe,
  getUserMeIsLoading,
  initialState,
  sliceName,
  usersSlice,
};
