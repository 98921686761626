import React, { FC, ReactNode } from 'react';

import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    primary: '#0074BD',
    secondary: '#F3DE6D',
    success: '#6CB56F',

    white: '#FFFFFF',
    grey: '#747474',
    lightGrey: '#E5E5E5',
    black: '#000000',
    lightGreen: '#85d788',
    red: '#fa0505',
  },
  fonts: ["'Inter', sans-serif", "'Lato', sans-serif"],
  fontSizes: {
    sm: '1em',
    md: '1.2em',
    lg: '1.5em',
    xlg: '2em',
  },
  breakPoints: {},
};
interface ITheme {
  children: ReactNode;
}
const Theme: FC<ITheme> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export { Theme };
