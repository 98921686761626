import { createBrowserRouter } from 'react-router-dom';

import { Router } from './router';

export const router = createBrowserRouter([
  {
    path: '*',
    element: <Router />,
  },
]);
