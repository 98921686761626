import { FC } from 'react';

import styled from 'styled-components';

import { Breadcrumbs, Typography } from '@mui/material';

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  left: 200px;
  height: 73px;
  width: calc(100vw - 200px);
  background: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 40px;
  color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  z-index: 999;
`;

const StyledViewing = styled.span`
  font-weight: 800;
`;

const StyledBreadCrumbs = styled(Typography)`
  && {
    display: flex;
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors.white};
  }
`;

const Header: FC = () => {
  return (
    <StyledHeader>
      <StyledBreadCrumbs variant="caption">
        <StyledViewing>QUALITY MANAGEMENT TOOL</StyledViewing>
        <Breadcrumbs color="white" separator=">">
          HEADER
        </Breadcrumbs>
      </StyledBreadCrumbs>
    </StyledHeader>
  );
};

export { Header };
