import { FC, lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useIsAuthenticated } from '@azure/msal-react';
import { Box, CircularProgress } from '@mui/material';

import { AppDispatch } from '../store/root';
import { fetchUserMe } from '../store/users';

import { Layout } from '../components';
import { AUTH_REDIRECT_PATH } from '../config';
import { HomePage, LoginPage, Page404 } from '../pages';
import { useCookie, loaderWrapperStyles } from '../utils';
import { PrivateOutlet } from './privateOutlet';
import { HOME, SNAGS, WORKFLOWS } from './routes';

const WorkflowsAppWrapper = lazy(() => import('../pages/workflows/wrapper'));
const SnagsAppWrapper = lazy(() => import('../pages/snags/wrapper'));

const Router: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { pathname, hash } = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const [, setRedirectURL] = useCookie(AUTH_REDIRECT_PATH);

  useEffect(() => {
    if (!['/login', '/', '/login#'].includes(pathname)) {
      setRedirectURL(pathname + hash);
    }
  }, [pathname, hash, setRedirectURL]);

  useEffect(() => {
    if (isAuthenticated) {
      setRedirectURL('', { expires: -1 });
    }
  }, [isAuthenticated, setRedirectURL]);

  useEffect(() => {
    dispatch(fetchUserMe());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />

      <Route path="/" element={<Layout />}>
        <Route path="" element={<PrivateOutlet />}>
          <Route path={HOME} element={<HomePage />} />

          <Route
            path={WORKFLOWS}
            element={
              <Suspense fallback={<Box sx={loaderWrapperStyles}>{<CircularProgress />}</Box>}>
                {<WorkflowsAppWrapper />}
              </Suspense>
            }
          />
          <Route
            path={SNAGS}
            element={
              <Suspense fallback={<Box sx={loaderWrapperStyles}>{<CircularProgress />}</Box>}>
                {<SnagsAppWrapper />}
              </Suspense>
            }
          />
        </Route>
      </Route>

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export { Router };
