import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ReduxStoreType } from '.';
import { Notification } from '../models/notification';

const sliceName = 'notifications';

interface INotificationReducerState {
  notifications: Notification[];
}

const initialState: INotificationReducerState = {
  notifications: [],
};

const triggerNotification = createAsyncThunk(
  `${sliceName}_TRIGGER`,
  async (notification: Notification, { dispatch }) => {
    setTimeout(() => {
      dispatch(removeNotification(notification.id));
    }, 5000);

    return notification;
  },
);

const removeNotification = createAsyncThunk(`${sliceName}_REMOVE`, async (id: string) => {
  return id;
});

const notificationsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(triggerNotification.fulfilled, (state, { payload }) => {
      if (!state.notifications.find((n) => n.id === payload.id)) {
        state.notifications = [...state.notifications, payload];
      }
    });

    builder.addCase(removeNotification.fulfilled, (state, { payload }) => {
      if (state.notifications.find((n) => n.id === payload)) {
        state.notifications = state.notifications.filter((n) => n.id !== payload);
      }
    });
  },
});

const getNotifications = (state: ReduxStoreType): Notification[] =>
  (state[sliceName] as INotificationReducerState).notifications;

export {
  getNotifications,
  initialState,
  INotificationReducerState,
  notificationsSlice,
  removeNotification,
  sliceName,
  triggerNotification,
};
