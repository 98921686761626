import axios, { AxiosError, AxiosInstance, AxiosResponse, Canceler } from 'axios';

import { RootStoreType } from '../store/root';

import { API_URL } from '../config';
import { User } from '../models/user';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export class Api {
  protected readonly axios: AxiosInstance;
  protected store: RootStoreType | null;
  protected getToken: any;

  public constructor() {
    this.store = null;
    this.getToken = null;
    this.axios = axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public setStore(store: RootStoreType) {
    this.store = store;
  }

  public setGetTokenFunc(getToken: any) {
    this.getToken = getToken;
  }

  public async getUser(): Promise<AxiosResponse<User>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<User>> => {
      const response = await this.axios.get<User>('/api/users/me', { headers: { Authorization: `Bearer ${token}` } });

      return response;
    };

    try {
      const token = await this.getToken();
      const response = await apiCall(token);

      return response;
    } catch (error: any) {
      return this.handleError<User>(error, apiCall, true);
    }
  }

  public async get<T>(route: string, params?: object, silent: boolean = false): Promise<AxiosResponse<T>> {
    if (cancel !== undefined) cancel();
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.get<T>(route, {
        headers: { Authorization: `Bearer ${token}` },
        params,
        cancelToken: new CancelToken((c) => (cancel = c)),
      });

      return response;
    };

    try {
      const token = await this.getToken();

      const response = await apiCall(token);

      return response;
    } catch (error: any) {
      return this.handleError<T>(error, apiCall);
    }
  }

  public async getFile<T = BlobPart>(route: string, params?: object): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.get<T>(route, {
        headers: { Authorization: `Bearer ${token}` },
        params,
        responseType: 'blob',
      });

      return response;
    };

    try {
      const token = await this.getToken();
      const response = await apiCall(token);

      return response;
    } catch (error: any) {
      return this.handleError<T>(error, apiCall);
    }
  }

  public async getDataFromStream(route: string, callback?: (dataChunk: string) => void, params?: object) {
    const apiCall = async (token: string | undefined, callback?: (dataChunk: string) => void) => {
      return this.axios.get(route, {
        headers: { Authorization: `Bearer ${token}` },
        params,
        responseType: 'stream',
        onDownloadProgress(progressEvent) {
          const dataChunk = progressEvent.currentTarget.response;

          callback?.(dataChunk);
        },
      });
    };

    try {
      const token = await this.getToken();
      await apiCall(token, callback);
    } catch (error: any) {
      return this.handleError<string>(error, apiCall);
    }
  }

  public async post<T>(route: string, data: any, params?: object): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.post<T>(route, data, { headers: { Authorization: `Bearer ${token}` }, params });

      return response;
    };

    try {
      const token = await this.getToken();
      const response = await apiCall(token);

      return response;
    } catch (error: any) {
      return this.handleError<T>(error, apiCall);
    }
  }

  public async patch<T>(route: string, data: any, params?: object, asJsonPath?: boolean): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const headers = { Authorization: `Bearer ${token}` };

      const patchHeaders = asJsonPath
        ? {
            ...headers,
            'Content-Type': 'application/json-patch+json',
          }
        : headers;

      const response = await this.axios.patch<T>(route, data, {
        headers: patchHeaders,
        params,
      });

      return response;
    };

    try {
      const token = await this.getToken();
      const response = await apiCall(token);

      return response;
    } catch (error: any) {
      //   return this.handleError<T>(error as AxiosError, apiCall);
      return this.handleError<T>(error, apiCall);
    }
  }

  public async put<T>(route: string, data: any, params?: object): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.put<T>(route, data, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      return response;
    };

    try {
      const token = await this.getToken();
      const response = await apiCall(token);

      return response;
    } catch (error: any) {
      return this.handleError<T>(error, apiCall);
    }
  }

  public async delete<T>(route: string, params?: object): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.delete<T>(route, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      return response;
    };

    try {
      const token = await this.getToken();
      const response = await apiCall(token);

      return response;
    } catch (error: any) {
      return this.handleError<T>(error, apiCall);
    }
  }

  private async handle401<T>(callback: (token?: string) => Promise<AxiosResponse<T>>, login: boolean) {
    if (login) {
      try {
        const token = await this.getToken();
        return callback(token);
      } catch (error) {
        throw error;
      }
    }
    try {
      const token = await this.getToken();
      return callback(token);
    } catch (error) {
      throw error;
    }
  }

  handleError<T>(error: AxiosError<T>, callback: (token?: string) => Promise<AxiosResponse<T>>, login = false) {
    if (error?.message?.includes('401')) {
      return this.handle401<T>(callback, login);
    }

    throw error;
  }
}

export default new Api();
