import { combineReducers } from 'redux';

import { notificationsSlice } from './notifications';
import { usersSlice } from './users';

const rootReducer = combineReducers({
  users: usersSlice.reducer,
  notifications: notificationsSlice.reducer,
});

type ReduxStoreType = ReturnType<typeof rootReducer>;

export { rootReducer, ReduxStoreType };
