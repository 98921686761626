import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useIsAuthenticated } from '@azure/msal-react';

const PrivateOutlet: FC = () => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export { PrivateOutlet };
